.customizer-sidebar.active button {
  @apply bg-theme-gray-1;
}
.customizer-sidebar button .svg-fill,
.customizer-sidebar button .svg-stroke {
  @apply transition-all duration-200;
}
.customizer-sidebar button .svg-fill {
  fill: #6b7280;
}
.customizer-sidebar button .svg-stroke {
  stroke: #6b7280;
}
.customizer-sidebar.active button .svg-fill {
  @apply fill-theme-primary;
}
.customizer-sidebar.active button .svg-stroke {
  @apply stroke-theme-primary;
}
.customizer-sidebar.active button span {
  @apply text-theme-primary;
}